import React from "react";

const Linkedin = ({ className }) => (
  <svg
    width="512px"
    height="512px"
    viewBox="0 0 512 512"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="linkedin" fillRule="nonzero">
        <path
          d="M511.872,512 L511.872,511.978667 L512,511.978667 L512,324.202667 C512,232.341333 492.224,161.578667 384.832,161.578667 C333.205333,161.578667 298.56,189.909333 284.416,216.768 L282.922667,216.768 L282.922667,170.154667 L181.098667,170.154667 L181.098667,511.978667 L287.125333,511.978667 L287.125333,342.72 C287.125333,298.154667 295.573333,255.061333 350.762667,255.061333 C405.141333,255.061333 405.952,305.92 405.952,345.578667 L405.952,512 L511.872,512 Z"
          id="Path"
        />
        <polygon
          id="Path"
          points="8.448 170.176 114.602667 170.176 114.602667 512 8.448 512"
        />
        <path
          d="M61.4826667,0 C27.5413333,0 0,27.5413333 0,61.4826667 C0,95.424 27.5413333,123.541333 61.4826667,123.541333 C95.424,123.541333 122.965333,95.424 122.965333,61.4826667 C122.944,27.5413333 95.4026667,0 61.4826667,0 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default Linkedin;
