export default [
  // UTRECHT
  {
    title: "Fullstack Developer (PHP en JS)",
    subTitle: "Enthousiaste Fullstack developer gezocht",
    href:"https://werken.dij.digital/fullstack-developer-php-en-javascript-2/nl",
    icon: "frontend",
    location: "Flexibel vanuit huis en op kantoor in Utrecht",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Wil je innovatieve end-to-end features ontwikkelen voor grote klanten?",
    secondQuestion: "Wil je werken in een omgeving met ervaren (medior / senior) developers?",
    thirdQuestion: "Wil jij jezelf continu blijven ontwikkelen?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },
  {
    title: "Senior Frontend Developer",
    subTitle: "Enthousiaste React frontend developer gezocht",
    href:"https://werken.dij.digital/senior-frontend-developer-react/nl",
    icon: "frontend",
    location: "Flexibel vanuit huis en op kantoor in Utrecht",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Wil je innovatieve end-to-end features ontwikkelen voor grote klanten?",
    secondQuestion: "Wil je werken in een omgeving met ervaren (medior / senior) developers?",
    thirdQuestion: "Wil jij jezelf continu blijven ontwikkelen?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },
  {
    title: "Medior Frontend Developer",
    subTitle: "Enthousiaste React frontend developer gezocht",
    href:"https://werken.dij.digital/react-frontend-developer-4/nl",
    icon: "frontend",
    location: "Flexibel vanuit huis en op kantoor in Utrecht",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Wil je innovatieve end-to-end features ontwikkelen voor grote klanten?",
    secondQuestion: "Wil je werken in een omgeving met ervaren (medior / senior) developers?",
    thirdQuestion: "Wil jij jezelf continu blijven ontwikkelen?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },
  {
    title: "Senior Backend Developer",
    subTitle: "Senior backend developer die goed gedijt bij veel verantwoordelijkheid",
    href:"https://werken.dij.digital/senior-php-developer-2/nl",
    icon: "php",
    location: "Flexibel vanuit huis en op kantoor in Utrecht",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Werken aan ingewikkelde, langdurige projecten",
    secondQuestion: "Goede, testbare code schrijven",
    thirdQuestion: "Naast software, ook jezelf blijven ontwikkelen?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },

  // APELDOORN
  {
    title: "Fullstack Developer (PHP en JS)",
    subTitle: "Enthousiaste Fullstack developer gezocht",
    href:"https://werken.dij.digital/fullstack-developer-php-en-javascript/nl",
    icon: "frontend",
    location: "Flexibel vanuit huis en op kantoor in Apeldoorn",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Wil je innovatieve end-to-end features ontwikkelen voor grote klanten?",
    secondQuestion: "Wil je werken in een omgeving met ervaren (medior / senior) developers?",
    thirdQuestion: "Wil jij jezelf continu blijven ontwikkelen?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },
  {
    title: "Senior Frontend Developer",
    subTitle: "Enthousiaste React frontend developer gezocht",
    href:"https://werken.dij.digital/senior-frontend-developer/nl",
    icon: "frontend",
    location: "Flexibel vanuit huis en op kantoor in Apeldoorn",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Wil je innovatieve end-to-end features ontwikkelen voor grote klanten?",
    secondQuestion: "Wil je werken in een omgeving met ervaren (medior / senior) developers?",
    thirdQuestion: "Wil jij jezelf continu blijven ontwikkelen?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },
  {
    title: "Medior Frontend Developer",
    subTitle: "Enthousiaste React frontend developer gezocht",
    href:"https://werken.dij.digital/react-frontend-developer-2/nl",
    icon: "frontend",
    location: "Flexibel vanuit huis en op kantoor in Apeldoorn",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Wil je innovatieve end-to-end features ontwikkelen voor grote klanten?",
    secondQuestion: "Wil je werken in een omgeving met ervaren (medior / senior) developers?",
    thirdQuestion: "Wil jij jezelf continu blijven ontwikkelen?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },
  {
    title: "Senior Backend Developer",
    subTitle: "Senior developer die goed gedijt bij veel verantwoordelijkheid",
    href:"https://werken.dij.digital/senior-backend-developer/nl",
    icon: "php",
    location: "Flexibel vanuit huis en op kantoor in Apeldoorn",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "Weet jij hoe je robuuste, complexe en duurzame applicatie en API’s moet opbouwen?",
    secondQuestion: "Heb jij je strepen al verdiend?",
    thirdQuestion: "Toe aan de volgende stap?",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  },
  {
    title: "Medior Backend Developer",
    subTitle: "Gezocht ervaren Backend developer die wil werken aan",
    href:"https://werken.dij.digital/medior-php-developer-3/nl",
    icon: "php",
    location: "Flexibel vanuit huis en op kantoor in Apeldoorn",
    workingHours: "Fulltime / Parttime",
    firstQuestion: "het ontwerp en de ontwikkeling van applicaties",
    secondQuestion: "uitdagende complexe projecten",
    thirdQuestion: "ontwikkelen van je eigen skills en kennis",
    text: "Kom dan samen met onze dedicated developers werken aan innovatieve, slimme webapplicaties en maatwerk api’s voor mooie klanten zoals de KNHB, Smulweb.nl, BENU Direct en Payroll Select.",
  }
]
