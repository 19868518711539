import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import Link from "next/link";

const StyledLink = styled.a`
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  color: ${color.black.base};

  &:hover {
    color: ${color.primary.base};
  }

  &:focus {
    outline: none;
  }
`;

const RouterLink = ({ href, children, className, active }) => (
  <Link href={href}>
    <StyledLink className={className} active={active} href={href}>
      {children}
    </StyledLink>
  </Link>
);

export default RouterLink;
