import React from "react";

const ChevronRight = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 19"
    width="12px"
    height="19px"
    className={className}
  >
    <path d="M12 9.5L2.5 19 0 16.6l7-7.1-7-7L2.5 0z" />
  </svg>
);

export default ChevronRight;
