import React from "react";
import styled from "styled-components";
import { font } from "../templates/ui";

const StyledMenuItem = styled.li`
  display: inline-block;
  font-family: ${font.family.head};
  font-weight: 700;
  text-transform: uppercase;
`;

const MenuItem = ({ children, className }) => (
  <StyledMenuItem className={className}>{children}</StyledMenuItem>
);

export default MenuItem;
