import React from "react";
import styled from "styled-components";
import MenuList from "./MenuList";
import MenuItem from "../atoms/MenuItem";
import RouterLink from "../atoms/Link";
import { color, font } from "../templates/ui";

const StyledRouterLink = styled(RouterLink)`
  color: ${color.gray.base};

  &:hover {
    color: ${color.body.base};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: block;
  font-family: ${font.family.base};
  font-weight: 500;
  font-size: 15px;
  text-transform: none;
  padding-left: 10px;
  margin-bottom: 5px;
`;

const NavigationMenu = () => (
  <MenuList>
    <StyledRouterLink href="/diensten">
      <StyledMenuItem>Diensten</StyledMenuItem>
    </StyledRouterLink>
    <StyledRouterLink href="/over-ons">
      <StyledMenuItem>Over ons</StyledMenuItem>
    </StyledRouterLink>
    <StyledRouterLink href="/cases">
      <StyledMenuItem>Cases</StyledMenuItem>
    </StyledRouterLink>
    <StyledRouterLink href="/vacatures">
      <StyledMenuItem>Vacatures</StyledMenuItem>
    </StyledRouterLink>
    <StyledRouterLink href="/contact">
      <StyledMenuItem>Contact</StyledMenuItem>
    </StyledRouterLink>
    <span>&nbsp;</span>
    <StyledRouterLink href="/privacyverklaring">
      <StyledMenuItem>Privacyverklaring</StyledMenuItem>
    </StyledRouterLink>
    <StyledRouterLink href="/cookieverklaring">
      <StyledMenuItem>Cookieverklaring</StyledMenuItem>
    </StyledRouterLink>
  </MenuList>
);

export default NavigationMenu;
