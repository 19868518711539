import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import ChevronRight from "./icons/ChevronRight";

const StyledReadMore = styled.span`
  transition: color 0.2s ease-in-out;
  align-self: flex-end;
  font-family: ${font.family.head};
  text-transform: uppercase;
  font-weight: 700;
  color: ${color.primary.base};
`;

const StyledReadMoreText = styled.span`
  margin-right: 4px;
  vertical-align: middle;
`;

const StyledChevronRight = styled(ChevronRight)`
  transition: fill 0.2s ease-in-out;
  fill: ${color.primary.base};
  height: 12px;
  width: 8px;
  vertical-align: middle;
`;

const ReadMoreButton = ({ children, className }) => (
  <StyledReadMore className={`rm-button ${className}`}>
    <StyledReadMoreText>{children}</StyledReadMoreText>
    <StyledChevronRight className="chevron-r" />
  </StyledReadMore>
);

export default ReadMoreButton;
