import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import Container from "../atoms/Container";
import Title from "../atoms/Title";
import InputItem from "../atoms/InputItem";
import Button from "../atoms/Button";
import ChevronRight from "../atoms/icons/ChevronRight";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url =
  "//digital.us20.list-manage.com/subscribe/post?u=f24d1c5e81112a8674dbb7511&amp;id=24d7f105bc";

const StyledNewsletter = styled.div`
  padding: 30px 0;
  background-color: #050505;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media only screen and (min-width: 991px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 30px;

  @media only screen and (min-width: 991px) {
    margin-bottom: 0;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }

  @media only screen and (min-width: 991px) {
    min-width: 565px;
    justify-content: flex-end;
  }
`;

const Description = styled.span`
  display: block;
  font-size: 15px;
  color: #c6c6c6;

  @media only screen and (min-width: 1250px) {
    min-width: 540px;
  }
`;

const StyledInputItem = styled(InputItem)`
  width: 100%;
  margin-bottom: 15px;

  input {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 10px;
    width: auto;

    input {
      width: 178px;
    }
  }
`;

const StyledChevronRight = styled(ChevronRight)`
  fill: ${color.body.base};
  width: 9px;
  height: 14px;
`;

const StyledButton = styled(Button)`
  height: 52px;
`;

const MessageWrapper = styled.div`
  margin-top: 10px;
  margin-left: 2px;
`;

const FormOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDescription = styled(Description)`
  color: ${(props) =>
    props.status === "error" ? color.primary.base : "#c6c6c6"};
`;

const CustomForm = ({ status, message, onValidated }) => {
  let email, name;

  const submit = () =>
    email &&
    name &&
    onValidated({
      EMAIL: email.value,
      FNAME: name.value,
    });

  return (
    <StyledNewsletter>
      <StyledContainer>
        <TextWrapper>
          <Title tag="h4" color="white" hasStripe={true}>
            Meld je aan voor de DIJ nieuwsbrief
          </Title>
          <Description>
            Alles waar we trots op zijn en nog meer, bundelen we op
            onregelmatige basis in een klapper van een nieuwsbrief. Deze wil je
            echt niet missen,
            <br />
            meld je daarom snel aan voor onze nieuwsbrief!
          </Description>
        </TextWrapper>
        <FormOuterWrapper>
          {status !== "success" && (
            <FormWrapper>
              <StyledInputItem
                placeholder="Naam"
                type="text"
                inputRef={(node) => (name = node)}
              />
              <StyledInputItem
                placeholder="Je e-mail"
                type="email"
                inputRef={(node) => (email = node)}
              />
              <StyledButton className="newsletter-submit" icon={<StyledChevronRight />} onClick={submit}>
                Aanmelden
              </StyledButton>
            </FormWrapper>
          )}
          {message && (
            <MessageWrapper>
              <StyledDescription status={status}>{message}</StyledDescription>
            </MessageWrapper>
          )}
        </FormOuterWrapper>
      </StyledContainer>
    </StyledNewsletter>
  );
};

class Newsletter extends React.Component {
  setInputRef(input) {
    this.inputRef = input;
  }
  render() {
    return (
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    );
  }
}

export default Newsletter;
