import React from "react";
import styled from "styled-components";

const StyledPage = styled.div`
  flex: 1 0 auto;
`;

const Page = ({ children }) => <StyledPage>{children}</StyledPage>;

export default Page;
