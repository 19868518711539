import React from "react";
import styled from "styled-components";
import Logo from "../atoms/icons/Logo";
import Container from "../atoms/Container";
import { color } from "../templates/ui";
import MainMenu from "./MainMenu";
import RouterLink from "../atoms/Link";
import MenuOverlay from "../molecules/MenuOverlay";
import dynamic from "next/dynamic";

const MenuToggle = dynamic(() => import("../atoms/MenuToggle"), { ssr: false });

const StyledContainer = styled(Container)`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -50%;
    width: 100%;
    height: 100%;
    background-color: ${color.gray.light};
    z-index: -1;
  }
`;

const StyledTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;

  @media only screen and (min-width: 991px) {
    padding: 30px 0;
  }
`;

const StyledLogo = styled(Logo)`
  width: 102px;
  height: 78px;

  @media only screen and (min-width: 991px) {
    width: 141px;
    height: 108px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
`;

const StyledMainMenu = styled(MainMenu)`
  display: none;

  @media only screen and (min-width: 991px) {
    display: flex;
  }
`;

const TopBar = ({ variant }) => {
  return (
    <>
      <StyledContainer>
        <StyledTopBar>
          <RouterLink href="/">
            <StyledLogo />
          </RouterLink>
          {variant !== "landing" && (
            <MenuWrapper>
              <StyledMainMenu />
              <MenuToggle />
            </MenuWrapper>
          )}
        </StyledTopBar>
      </StyledContainer>
      <MenuOverlay />
    </>
  );
};

export default TopBar;
