import React from "react";
import styled from "styled-components";
import LogoInverted from "../atoms/icons/LogoInverted";
import Container from "../atoms/Container";
import MenuList from "./MenuList";
import MenuItem from "../atoms/MenuItem";
import { color } from "../templates/ui";
import Link from "next/link";
import { useRouter } from "next/router";

const StyledMenuOverlay = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  opacity: 0;
  transition: opacity 0s ease-in-out;
  background-color: black;
  color: white;
  pointer-events: none;

  .menu--is-open & {
    transition: opacity 0.6s ease-in-out 0.3s;
    overflow-y: auto;
  }
`;

const OverlayInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const StyledRouterLink = styled.a`
  display: block;
  color: ${(props) =>
    props.active ? `${color.gray.dark}` : `${color.body.base}`};
  text-align: right;
  margin-bottom: 10px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 15px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: ${color.gray.dark};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  position: relative;
  display: inline-block;
  font-size: 26px;
  line-height: 2.2rem;

  &:before {
    content: ${(props) => (props.active ? "' '" : "none")};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -50px;
    width: 27px;
    height: 27px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzhweCIgaGVpZ2h0PSIzN3B4IiB2aWV3Qm94PSIwIDAgMzggMzciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkhvbWVwYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAwMC4wMDAwMDAsIC02Ni4wMDAwMDApIiBmaWxsPSIjREEzMDNBIj4gICAgICAgICAgICA8ZyBpZD0iTWVudSI+ICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iRmlsbC0yIiBwb2ludHM9IjEwMDcuODA1NTMgMTAyLjk4NjIxNSAxMDAwLjg0OTIxIDk2LjAzMDI3NDUgMTAzMC42NjAyOCA2Ni4yMTkxNDgzIDEwMzcuNjE2MjggNzMuMTc1NDEwMSAxMDA3LjgwNTUzIDEwMi45ODYyMTUiPjwvcG9seWxpbmU+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media only screen and (min-width: 576px) {
    font-size: 34px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 54px;
    line-height: 3rem;

    &:before {
      width: 37px;
      height: 37px;
    }
  }
`;

const StyledMenuList = styled(MenuList)`
  position: relative;
  padding-bottom: 40px;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #242424;

    @media only screen and (min-width: 768px) {
      width: calc(100% + 40px);
    }
  }
`;

const MenuWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    padding-top: 100px;
  }
`;

const ContactInfo = styled.div`
  padding-top: 40px;
  text-align: right;
`;

const Address = styled.address`
  font-style: normal;
  font-weight: 500;
  color: #515151;
  margin-bottom: 20px;
  
  span {
    display: block;
    color: white;
    font-weight: 600;
  }
`;

const PhoneNumber = styled.a`
  display: block;
  color: ${color.body.base};
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 10px;
  text-decoration: none;
`;

const Email = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: #515151;
  text-decoration: none;
`;

const MenuOverlay = () => {
  const router = useRouter();
  const currentUrl = router.pathname;

  return (
    <StyledMenuOverlay className="menu">
      <Container>
        <OverlayInner>
          <LogoInverted />
          <MenuWrapper>
            <StyledMenuList>
              <Link href="/">
                <StyledRouterLink href="/" active={currentUrl === "/"}>
                  <StyledMenuItem active={currentUrl === "/"}>
                    Home
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
              <Link href="/diensten">
                <StyledRouterLink
                  href="/diensten"
                  active={currentUrl === "/diensten"}
                >
                  <StyledMenuItem active={currentUrl === "/diensten"}>
                    Diensten
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
              <Link href="/over-ons">
                <StyledRouterLink
                  href="/over-ons"
                  active={currentUrl === "/over-ons"}
                >
                  <StyledMenuItem active={currentUrl === "/over-ons"}>
                    Over ons
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
              <Link href="/cases">
                <StyledRouterLink
                  href="/cases"
                  active={currentUrl === "/cases"}
                >
                  <StyledMenuItem active={currentUrl === "/cases"}>
                    Cases
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
              <Link href="/blogs">
                <StyledRouterLink
                  href="/blogs"
                  active={currentUrl === "/blogs"}
                >
                  <StyledMenuItem active={currentUrl === "/blogs"}>
                    Blog
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
              <Link href="/vacatures">
                <StyledRouterLink
                  href="/vacatures"
                  active={currentUrl === "/vacatures"}
                >
                  <StyledMenuItem active={currentUrl === "/vacatures"}>
                    Vacatures
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
              <Link href="/digitaal-spreekuur">
                <StyledRouterLink
                  href="/digitaal-spreekuur"
                  active={currentUrl === "/digitaal-spreekuur"}
                >
                  <StyledMenuItem active={currentUrl === "/digitaal-spreekuur"}>
                    Digitaal spreekuur
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
              <Link href="/contact">
                <StyledRouterLink
                  href="/contact"
                  active={currentUrl === "/contact"}
                >
                  <StyledMenuItem active={currentUrl === "/contact"}>
                    Contact
                  </StyledMenuItem>
                </StyledRouterLink>
              </Link>
            </StyledMenuList>
            <ContactInfo>
              <Address>
                <span>Kantoor Apeldoorn</span>
                Roggestraat 111 <br />
                7311 CC Apeldoorn
              </Address>
              <Address>
                <span>Kantoor Utrecht</span>
                Europalaan 40<br/>
                3526 KS Utrecht
              </Address>
              <PhoneNumber href="tel:0557507811">055 750 7811</PhoneNumber>
              <Email href="mailto:info@dij.digital">info@dij.digital</Email>
            </ContactInfo>
          </MenuWrapper>
        </OverlayInner>
      </Container>
    </StyledMenuOverlay>
  );
};

export default MenuOverlay;
