import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";
import Container from "../atoms/Container";
import LogoInverted from "../atoms/icons/LogoInverted";
import NavigationMenu from "../molecules/NavigationMenu";
import ReadMoreButton from "../atoms/ReadMoreButton";
import RouterLink from "../atoms/Link";
import Instagram from "../atoms/icons/Instagram";
import Facebook from "../atoms/icons/Facebook";
import Linkedin from "../atoms/icons/Linkedin";

const StyledFooter = styled.footer`
  padding: 15px 0;
  background-color: #101010;

  @media only screen and (min-width: 991px) {
    padding: 30px 0;
  }
`;

const FooterTitle = styled.h5`
  position: relative;
  color: ${color.body.base};
  margin-bottom: 15px;
  font-family: ${font.family.head};
  font-weight: 700;
  padding-left: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 3px;
    background-color: ${color.primary.base};
  }
`;

const Address = styled.address`
  font-style: normal;
  color: ${color.gray.base};
  font-family: ${font.family.base};
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  line-height: 1.4rem;
  margin-bottom: 15px;
`;

const Links = styled.div`
  margin-bottom: 15px;
`;

const Link = styled.a`
  display: block;
  font-family: ${font.family.base};
  font-weight: 700;
  font-size: 14px;
  padding-left: 10px;
  color: ${color.gray.base};
  text-decoration: none;
  margin-bottom: 5px;
`;

const Info = styled.span`
  display: block;
  color: ${color.gray.base};
  font-family: ${font.family.base};
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 5px;
  line-height: 1.4rem;
`;

const StyledReadMoreButton = styled(ReadMoreButton)`
  display: block;
  padding-left: 10px;
  margin-top: 15px;
`;

const StyledContainer = styled(Container)`
  padding: 0;

  .mb-2 {
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 768px) {
    padding: 20px;
  }
`;

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${color.gray.base};
  
  span {
    display: block;
    font-weight: 600;
    color: white;
  }
`;

const IsoLogo = styled.img`
  max-width: 80px;
  height: 124px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledInfo = styled(Info)`
  display: flex;
`;

const StyledSocialLinks = styled.div`
  margin-bottom: 30px;
`;
const StyledSocialLinksWrapper = styled.div`
  padding-left: 10px;
`;

const StyledSocialLink = styled.div`
  display: inline-block;
  margin-right: 15px;

  a {
    display: block;

    &:hover {
      svg {
        fill: ${color.gray.white};
      }
    }
  }

  svg {
    width: auto;
    height: 34px;
    transition: fill 0.2s ease-in-out;
    fill: ${color.gray.dark};
    margin-right: 6px;
  }
`;

const Footer = ({ variant }) => (
  <StyledFooter>
    <StyledContainer>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-lg-2 mb-2">
          <LogoInverted />
        </div>
        {variant !== "landing" && (
          <div className="col-xs-12 col-sm-6 col-lg-2 mb-2">
            <FooterTitle>Navigatie</FooterTitle>
            <NavigationMenu />
          </div>
        )}
        <div className="col-xs-12 col-sm-6 col-lg-2 mb-2">
          <FooterTitle>Gegevens</FooterTitle>
          <Address>
            <StyledLink
              href="https://goo.gl/maps/Ey43PmHJVRMPnAU59"
              target="_blank"
            >
              <span>Kantoor Apeldoorn</span>
              Roggestraat 111 <br />
              7311 CC Apeldoorn
            </StyledLink>
          </Address>
          <Address>
            <StyledLink
              href="https://goo.gl/maps/SgL8rCkcNeeTwWAn7"
              target="_blank"
            >
              <span>Kantoor Utrecht</span>
              Europalaan 40<br/>
              3526 KS Utrecht
            </StyledLink>
          </Address>
          <Links>
            <Link href="tel:0557507811">055 750 7811</Link>
            <Link href="mailto:info@dij.digital">info@dij.digital</Link>
          </Links>
          <Info>KVK-08177991</Info>
          <Info>BTW-NL819649612B01</Info>
        </div>
        {variant !== "landing" && (
          <div className="col-xs-12 col-sm-6 col-lg-3 mb-2">
            <FooterTitle>Digitaal spreekuur</FooterTitle>
            <Info>
              Daag ons uit! Deel jouw digitale vraagstuk en we zetten er
              ongenadig hard en geheel vrijblijvend onze tanden in om te kijken
              hoe we jouw organisatie in een flinke digitale versnelling kunnen
              brengen.
            </Info>
            <StyledRouterLink href="/digitaal-spreekuur">
              <StyledReadMoreButton>Lees meer</StyledReadMoreButton>
            </StyledRouterLink>
          </div>
        )}
        <div className="col-xs-12 col-sm-6 col-lg-3">
          <StyledSocialLinks>
            <FooterTitle>Volg ons ook op</FooterTitle>
            <StyledSocialLinksWrapper>
              <StyledSocialLink>
                <a
                  href="https://www.instagram.com/dij.digital/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Instagram />
                </a>
              </StyledSocialLink>
              <StyledSocialLink>
                <a
                  href="https://www.facebook.com/DIJdigital/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Facebook />
                </a>
              </StyledSocialLink>
              <StyledSocialLink>
                <a
                  href="https://www.linkedin.com/company/dijdigital/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Linkedin />
                </a>
              </StyledSocialLink>
            </StyledSocialLinksWrapper>
          </StyledSocialLinks>
          {variant !== "landing" && (
            <>
              <FooterTitle>ISO 27001 &amp; NEN 7510</FooterTitle>
              <StyledInfo>
                <IsoLogo src={require("../assets/img/content/iso-27001.png")} />
                <IsoLogo src={require("../assets/img/content/nen-7510.png")} />
              </StyledInfo>
            </>
          )}
        </div>
        {variant === "landing" && (
          <div className="col-xs-12 col-sm-6 col-lg-3">
            <FooterTitle>ISO 27001 &amp; NEN 7510</FooterTitle>
            <StyledInfo>
              <IsoLogo src={require("../assets/img/content/iso-27001.png")} />
              <IsoLogo src={require("../assets/img/content/nen-7510.png")} />
            </StyledInfo>
          </div>
        )}
      </div>
    </StyledContainer>
  </StyledFooter>
);

export default Footer;
