import React from "react";

const Facebook = ({ className }) => (
  <svg
    width="53px"
    height="97px"
    viewBox="0 0 53 97"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="facebook" fillRule="nonzero">
        <path
          d="M50.089,0.02 L37.624,0 C23.62,0 14.57,9.285 14.57,23.656 L14.57,34.563 L2.037,34.563 C0.954,34.563 0.077,35.441 0.077,36.524 L0.077,52.327 C0.077,53.41 0.955,54.287 2.037,54.287 L14.57,54.287 L14.57,94.163 C14.57,95.246 15.447,96.123 16.53,96.123 L32.882,96.123 C33.965,96.123 34.842,95.245 34.842,94.163 L34.842,54.287 L49.496,54.287 C50.579,54.287 51.456,53.41 51.456,52.327 L51.462,36.524 C51.462,36.004 51.255,35.506 50.888,35.138 C50.521,34.77 50.021,34.563 49.501,34.563 L34.842,34.563 L34.842,25.317 C34.842,20.873 35.901,18.617 41.69,18.617 L50.087,18.614 C51.169,18.614 52.046,17.736 52.046,16.654 L52.046,1.98 C52.046,0.899 51.17,0.022 50.089,0.02 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default Facebook;
