import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledInputItem = styled.div`
  position: relative;
  display: block;
`;

const StyledInput = styled.input`
  transition: border-color 0.2s ease-in-out;
  padding: 14px;
  width: 178px;
  font-family: ${font.family.base};
  font-weight: 400;
  color: ${color.body.base};
  font-size: 15px;
  background-color: ${color.gray.darker};
  border: 2px solid ${color.gray.dark};
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: ${color.body.base};
  -webkit-transform: translate3d(0, 0, 0);

  &:focus {
    outline: none;
    border-color: ${color.primary.base};
  }
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: ${color.base.base};
  font-family: ${font.family.head};
  font-weight: 700;
`;

const InputItem = ({
  placeholder,
  className,
  type,
  name,
  inputRef,
  labelText,
  onChange,
  onBlur,
  value,
}) => (
  <StyledInputItem className={className}>
    {labelText && <StyledLabel>{labelText}</StyledLabel>}
    <StyledInput
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      type={type}
      ref={inputRef}
    />
  </StyledInputItem>
);

export default InputItem;
