import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledBadge = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  background-color: ${color.primary.base};
  vertical-align: top;
  color: ${color.body.base};
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  font-family: ${font.family.head};
  top: -10px;
`;

const Badge = ({ amount }) => <StyledBadge>{amount}</StyledBadge>;

export default Badge;
