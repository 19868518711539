import React from "react";
import MenuList from "../molecules/MenuList";
import MenuItem from "../atoms/MenuItem";
import RouterLink from "../atoms/Link";
import Badge from "../atoms/Badge";
import styled from "styled-components";
import vacancies from '../data/vacancies';

const StyledMenuList = styled(MenuList)`
  display: flex;
  align-items: center;
`;

const StyledRouterLink = styled(RouterLink)`
  padding: 0 15px;
`;

const MainMenu = ({ className }) => (
  <StyledMenuList className={className}>
    <StyledRouterLink href="/vacatures">
      <MenuItem>Vacatures</MenuItem>
      <Badge amount={vacancies.length} />
    </StyledRouterLink>
    <StyledRouterLink href="/contact">
      <MenuItem>Contact</MenuItem>
    </StyledRouterLink>
  </StyledMenuList>
);

export default MainMenu;
