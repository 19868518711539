import React from "react";
import styled from "styled-components";

const StyledMenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MenuList = ({ children, className }) => (
  <StyledMenuList className={className}>{children}</StyledMenuList>
);

export default MenuList;
